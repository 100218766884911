import React, { useEffect, useState } from 'react';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import { Navbar } from './Components/Catalyst/navbar';
import AppSidebar from './AppSidebar';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Divider } from './Components/Catalyst/divider';
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from './Components/Catalyst/dropdown';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from './Components/Catalyst/table';
import { HoaSchema } from './hoa-api-client';

interface DashboardProps {
    currentPage: string;
}

type Hoa = HoaSchema;

// TODO: fetch actual data. This will be a placeholder for now
const stats = [
    { name: 'Homes Under Management', stat: '129' },
    { name: 'Open Violations', stat: '5' },
    { name: 'Fines Paid', stat: '$15,123' },
];

const dateRanges = [
    { label: 'Last 7 days', value: '7d' },
    { label: 'Last 30 days', value: '30d' },
    { label: 'Last 90 days', value: '90d' },
    { label: 'Year to date', value: 'ytd' },
];

const mockHoas: Hoa[] = [
    {
        id: 1,
        name: 'Sunny Hills HOA',
        address: '123 Main St, Cityville, ST 12345',
        contact_email: 'info@sunnyhillshoa.com',
        contact_phone: '(555) 123-4567',
        is_active: true,
        date_created: '2023-01-15T00:00:00Z',
    },
    {
        id: 2,
        name: 'Pine Grove Community',
        address: '456 Oak Rd, Townsburg, ST 67890',
        contact_email: 'contact@pinegrove.org',
        contact_phone: '(555) 987-6543',
        is_active: true,
        date_created: '2023-03-22T00:00:00Z',
    },
    // Add more mock data as needed
];

const Dashboard: React.FC<DashboardProps> = ({ currentPage }) => {
    const [selectedRange, setSelectedRange] = useState(dateRanges[1]);
    const [hoas, setHoas] = useState<HoaSchema[]>([]);

    useEffect(() => {
        // Replace this with actual API call when ready
        setHoas(mockHoas);
    }, []);

    const handleRowClick = (hoa: HoaSchema) => {
        console.log(`Clicked on HOA: ${hoa.name}`);
        // You can add more logic here, such as navigating to a detail page
    };

    return (
        <SidebarLayout
            sidebar={<AppSidebar currentPage={currentPage} />}
            navbar={<Navbar>{/* Your navbar content */}</Navbar>}
        >
            {/* Your page content */}
            <Heading>Welcome to your Dashboard</Heading>
            <Subheading>More to come soon!</Subheading>
            <Divider className="mt-4 mb-6"></Divider>

            <div className="flex justify-between items-center mb-4">
                <Heading
                    level={4}
                    className="text-base font-semibold leading-6 text-zinc-900 dark:text-white"
                >
                    Key Metrics
                </Heading>
                <Dropdown>
                    <DropdownButton className="flex items-center gap-x-1 text-sm font-medium leading-6 text-zinc-900 dark:text-white">
                        {selectedRange.label}
                        <ChevronDownIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </DropdownButton>
                    <DropdownMenu>
                        {dateRanges.map((range) => (
                            <DropdownItem
                                key={range.value}
                                onClick={() => setSelectedRange(range)}
                            >
                                {range.label}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>

            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {stats.map((item) => (
                    <div
                        key={item.name}
                        className="overflow-hidden rounded-lg bg-white dark:bg-zinc-800 px-4 py-5 shadow sm:p-6 transition-colors duration-200"
                    >
                        <dt className="truncate text-sm font-medium text-zinc-500 dark:text-zinc-400">
                            {item.name}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-zinc-900 dark:text-white">
                            {item.stat}
                        </dd>
                    </div>
                ))}
            </dl>
            <Divider className="mt-4 mb-6"></Divider>
            <Heading level={4} className="mb-4">
                HOA List
            </Heading>
            <div className="bg-white dark:bg-zinc-800 rounded-lg shadow transition-colors duration-200">
                <div className="px-4 py-5 sm:p-6">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeader>Name</TableHeader>
                                <TableHeader>Address</TableHeader>
                                <TableHeader>Contact Email</TableHeader>
                                <TableHeader>Contact Phone</TableHeader>
                                <TableHeader>Status</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hoas.map((hoa) => (
                                <TableRow
                                    key={hoa.id}
                                    onClick={() => handleRowClick(hoa)}
                                    className="cursor-pointer hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-150"
                                >
                                    <TableCell>{hoa.name}</TableCell>
                                    <TableCell>{hoa.address}</TableCell>
                                    <TableCell>{hoa.contact_email}</TableCell>
                                    <TableCell>{hoa.contact_phone}</TableCell>
                                    <TableCell>
                                        <span
                                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${hoa.is_active ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'}`}
                                        >
                                            {hoa.is_active
                                                ? 'Active'
                                                : 'Inactive'}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            {/* <h1 className="text-2xl font-bold mb-4">
                Welcome to your Dashboard
            </h1>
            <p>This is where your main dashboard content will go.</p> */}
        </SidebarLayout>
    );
};

export default Dashboard;
