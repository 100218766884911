import { AuthApi, Configuration } from './auth-api-client';
import { HoaApi, Configuration as HoaConfiguration } from './hoa-api-client';
import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    InternalAxiosRequestConfig,
} from 'axios';
import {
    clearAllTokens,
    getRefreshToken,
    getRememberMe,
    getToken,
    refreshAccessToken,
    setToken,
    tokenIsUnexpired,
} from './TokenUtils';

const AUTH_SVC_BASE_URL = window._env_.REACT_APP_AUTH_SVC;
const HOA_SVC_BASE_URL = window._env_.REACT_APP_HOA_SVC;

// Initialize the API client
const apiConfig = new Configuration({
    basePath: AUTH_SVC_BASE_URL,
});

export const authService = new AuthApi(apiConfig);

const hoaConfig = new HoaConfiguration({
    basePath: HOA_SVC_BASE_URL,
});

// Create separate axios instances for each service
const authAxiosInstance: AxiosInstance = axios.create({
    baseURL: AUTH_SVC_BASE_URL,
});

const hoaAxiosInstance: AxiosInstance = axios.create({
    baseURL: HOA_SVC_BASE_URL,
});

// Common request interceptor
const requestInterceptor = async (
    config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
    let token = getToken();

    if (token) {
        const isUnexpired = await tokenIsUnexpired(token);

        if (!isUnexpired) {
            const refreshToken = getRefreshToken();
            if (refreshToken) {
                const newToken = await refreshAccessToken(refreshToken);
                if (newToken) {
                    token = newToken;
                    setToken(newToken, !!getRememberMe());
                } else {
                    // Refresh failed, clear tokens and redirect to login
                    clearAllTokens();
                    window.location.href = '/login';
                    throw new axios.Cancel(
                        'Operation canceled due to token refresh failure.'
                    );
                }
            } else {
                // No refresh token available, clear tokens and redirect to login
                clearAllTokens();
                window.location.href = '/login';
                throw new axios.Cancel(
                    'Operation canceled due to missing refresh token.'
                );
            }
        }

        config.headers = config.headers || {};
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
};

// Common response interceptor to redirect 401 to login page
const responseInterceptor = (error: any) => {
    if (error.response && error.response.status === 401) {
        clearAllTokens();
        window.location.href = '/login';
    }
    return Promise.reject(error);
};

// add api clients as needed to this loop to add both interceptors
[hoaAxiosInstance].forEach((instance) => {
    instance.interceptors.request.use(requestInterceptor, (error) =>
        Promise.reject(error)
    );
    instance.interceptors.response.use(
        (response) => response,
        responseInterceptor
    );
});

// Use the axios instance with the HOA API
export const hoaService = new HoaApi(
    hoaConfig,
    HOA_SVC_BASE_URL,
    hoaAxiosInstance
);

// Export the axios instances for use in components
export { authAxiosInstance, hoaAxiosInstance };
